
import 'materialize-css/dist/js/materialize'

document.addEventListener("DOMContentLoaded", function () {
    M.FormSelect.init(document.querySelectorAll('select'));
    const companyTypes = document.querySelectorAll(".company_type_element");
    companyTypes.forEach(company_type => {
        company_type.addEventListener("click", e => {
            const logisticsOp = document.querySelector('input[name="user[company_attributes][logistics_operator]"]:checked').value;
            if (logisticsOp == "0") {
                const companyTypesTmp = document.querySelectorAll(".company_type_element");
                Array.from(companyTypesTmp).filter(ct => ct.value != e.target.value).forEach(ct => {
                    ct.checked = false;
                });
            }
        });
    });

    const logisticOptions = document.querySelectorAll(".logistics_operator_item");
    logisticOptions.forEach(option => {
        option.addEventListener("click", e => {
            const companyTypesTmp = document.querySelectorAll(".company_type_element");
            companyTypes.forEach(ct => {
                ct.checked = false;
            });
        });
    });

});
